import * as React from "react"
import PropTypes from "prop-types"

import "./textSection.css"

const Title = ({ className, title, subtitle, headingTag }) => {

  const HeadingTag = headingTag || 'h2';

  return (
    <div className="title-container">
        {subtitle &&
          <span className="section-subtitle">{subtitle}</span>
        }
        <HeadingTag className={`section-title ${className}`}>
          {title}
        </HeadingTag>
    </div>
  )
}

Title.defaultProps = {
  headingTag: `h2`,
}

Title.propTypes = {
  headingTag: PropTypes.string,
}

export default Title